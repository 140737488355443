:root {
  --white: #ffffff;
  --beige: #fbf7ee;
  --light-grey: #d8d7d7;
  --light-grey-1: #c5c5c5;
  --light-grey-2: #b5b5b5;
  --light-green: #EFF4F6BF;
  --sky-blue: #00aadb;
  --ocean-blue: #015067;
  --ocean-blue-tr:rgba(0,170,219,0.27);
  --ocean-blue-tr-2:rgba(0,170,219,0.11);
  --dark-grey: #494949;
  --yellow: #f6b93b;
  --red: #ec4738;

  //couleurs client
  --tomate: #ef4130;
  --tangerine: #f58220;
  --mimosa: #ffcb05;
  --azur: #a1daf8;
  --bonbon: #e33e80;
  --canari: #f9ec00;
  --celeste: #00aadb;
  --lavande: #8981b5;
  --vert-eau: #94D1be;
  --nuit: #00002c;
  --violine: #583f99;
  --menthol: #6bb989;
  --blue: #3282f6;
  --black: #000000;
  --orange: #f97516;
}
