/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/quill/dist/quill.bubble.css";

html, body { height: 100%; }
body { margin: 0; font-family: "TradeGothic", "Helvetica Neue", sans-serif;}

@layer base {
  @font-face {
    font-family: "TradeGothic";
    font-weight: 400;
    src: url(assets/fonts/trade-gothic-lt-std.otf) format("opentype");
  }

  @font-face {
    font-family: "TradeGothicBold";
    font-weight: 800;
    src: url(assets/fonts/trade-gothic-lt-std-bold.otf) format("opentype");
  }

  @font-face {
    font-family: "SegoeUIRegular";
    font-weight: 600;
    src: url(assets/fonts/segoe-ui.woff) format("woff");
  }
}

.mdc-notched-outline__notch
{
  border-right: none;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #aaaaaa !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused,
.mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused,
.mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused,
.mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline,
.mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline,
.mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline,
.mdc-notched-outline__trailing{
  border-color: black !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after
{
  color: black !important;
}

//********************************_App-colors_********************************





  //TODO @LCO to delete when prod

.redD {
  border: red dashed 1px;
}

.blackD {
  border: black dashed 1px;
}

.orangeD {
  border: orange dashed 1px;
}

.greenD {
  border: darkgreen dashed 1px;
}

.whiteD {
  border: white dashed 1px;
}


.redS {
  border: red solid 1px;
}

.blackS {
  border: black solid 1px;
}

.orangeS {
  border: orange solid 1px;
}

.greenS {
  border: darkgreen solid 1px;
}

.whiteS {
  border: white solid 1px;
}

